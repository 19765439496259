<template>
    <div>
        <div class="container">
            <div class="left">
                <img src="../../public/images/404.png" alt />
            </div>
            <div class="right">
                <h1>您访问的页面出错了</h1>
                <p>管理员正在以火箭的速度赶来处理</p>
                <p style="margin:20px 0 30px 0">您还可以进行以下操作</p>
                <el-button type="danger" round @click="hui">返回首页</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        hui() {
            this.$router.push({ path: '/home' });
        },
    },
};
</script>
<style lang="less" scoped>
.container {
    padding: 70px 30px;
    margin: 0 auto;
    width: 1000px;
    display: flex;
    align-items: center;
}
p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(141, 141, 141, 1);
}
.left {
    padding-right: 10px;
    img {
        width: 100%;
    }
}
</style>